import React from 'react'
import IconBase from './IconBase'

const Login = props => (
  <IconBase viewBox="0 0 55.78 45.71" {...props}>
    <g transform="translate(158.81 -930.3)">
      <path
        className="st0"
        d="m-135.81 972.22s0.02-0.77-0.3-0.92c-0.46-0.1-0.93-0.14-1.4-0.1h-11.09c-1.51 0.01-2.96-0.6-4-1.7-1.08-1.05-1.69-2.49-1.7-4v-25.1c-0.01-1.51 0.6-2.96 1.7-4 1.05-1.08 2.49-1.69 4-1.7h11.4c0.25-0.01 0.49-0.07 0.7-0.2 0.19-0.2 0.33-0.44 0.4-0.7 0.08-0.26 0.11-0.53 0.1-0.8v-1.6c-0.01-0.61-0.49-1.09-1.1-1.1h-11.4c-2.73 0.01-5.35 1.08-7.3 3-2.01 1.88-3.1 4.55-3 7.3v25.1c0.01 2.73 1.08 5.35 3 7.3 1.88 2.01 4.55 3.1 7.3 3h11.4c0.25-0.01 0.49-0.07 0.7-0.2 0.19-0.2 0.33-0.44 0.4-0.7 0.1-0.3 0.19-0.72 0.19-0.72v-0.73c0.04-0.48 0.04-0.95 0-1.43z"
      />
      <path
        className="st0"
        d="m-103.7 951.3l-19.4-19.4c-0.86-0.88-2.28-0.9-3.16-0.04l-0.04 0.04c-0.43 0.42-0.68 1-0.7 1.6v10.3h-16c-1.25-0.02-2.28 0.98-2.3 2.23v0.07 13.7c-0.02 1.25 0.98 2.28 2.23 2.3h0.07 16v10.3c-0.02 1.25 0.98 2.28 2.23 2.3h0.07c0.61 0.03 1.2-0.23 1.6-0.7l19.4-19.4c0.9-0.92 0.9-2.38 0-3.3z"
      />
    </g>
  </IconBase>
)

export default Login
