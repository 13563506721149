import React from 'react'
import styled from 'styled-components'
import {Item} from './OrderItem'
import useCustomTranslation from 'lib/customT'

const OrderTotal =  styled(Item)`
    background-color: #f6f6f6;
    color: #8395ab;
    font-size: 1.2em;
    font-weight: bold;
`

const TaxTotal =  styled(Item)`
    background-color: #f6f6f6;
    color: #8395ab;
    font-size: 1.2em;
`

function formatDanishNumber(number) {
  // Ensure the number has two decimal places
  let parts = number.toFixed(2).split('.');

  // Add the thousands separator (dot) for the integer part
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');

  // Replace the decimal point with a comma
  return parts.join(',');
}

const Total = ({total, tax}) => {
  const t = useCustomTranslation()

  return (
    <>
    <TaxTotal>
      <p>{t('Heraf moms')}</p>
      <p>{formatDanishNumber(tax)} DKK</p>
    </TaxTotal>
    <OrderTotal>
      <p>{t('TOTAL Inkl. Moms')}</p>
      <p>{total} DKK</p>
    </OrderTotal>
    </>
  )
}

export default Total