import React from 'react'
import IconBase from './IconBase'

const Payment = props =>
  <IconBase viewBox="0 0 59.9 39.9" {...props}>
    <g transform="translate(-224.89 -1126.3)">
      <path d="m278.5 1126.3h-47.4c-3.4 0-6.2 2.8-6.2 6.2v27.4c0 3.4 2.8 6.2 6.2 6.2h47.4c3.4 0 6.2-2.8 6.2-6.2v-27.4c0.1-3.4-2.7-6.2-6.2-6.2zm3.8 33.6c0 2.1-1.7 3.7-3.7 3.7h-47.4c-2.1 0-3.7-1.7-3.7-3.7v-27.4c0-2.1 1.7-3.7 3.7-3.7h47.4c2.1 0 3.7 1.7 3.7 3.7v27.4z"/>
      <path d="m282.3 1133.7l-54.9 0.1v10h54.9c0.1-0.1 0-10.1 0-10.1zm0 7.6l-54.9-0.1v-5h54.9v5.1z"/>
      <path d="m248.6 1151.2h-15c-0.7 0-1.3 0.6-1.3 1.3s0.6 1.3 1.3 1.3h15c0.7 0 1.3-0.6 1.3-1.3-0.1-0.7-0.6-1.3-1.3-1.3z"/>
      <path d="m248.6 1156.2h-15c-0.7 0-1.3 0.6-1.3 1.3s0.6 1.3 1.3 1.3h15c0.7 0 1.3-0.6 1.3-1.3-0.1-0.7-0.6-1.3-1.3-1.3z"/>
      <path d="m273.5 1148.7h-2.5c-2.1 0-3.7 1.7-3.7 3.7v2.5c0 2.1 1.7 3.7 3.7 3.7h2.5c2.1 0 3.7-1.7 3.7-3.7v-2.5c0.1-2-1.6-3.7-3.7-3.7zm1.3 6.3c0 0.7-0.6 1.2-1.2 1.2h-2.6c-0.7 0-1.2-0.6-1.2-1.2v-2.5c0-0.7 0.6-1.2 1.2-1.2h2.5c0.7 0 1.2 0.6 1.2 1.2v2.5z"/>
    </g>
  </IconBase>

export default Payment
