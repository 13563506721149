import React, { useState } from 'react';




const PriceStructure = () => {




  return (
    <>
      hello PriceStructure

    </>
  );
};

export default PriceStructure;