import React from 'react';
import IconBase from './IconBase';

const PausedModule = (props) => (
  <IconBase viewBox="0 -960 960 960" {...props}>
    <path d="M360-320h80v-320h-80v320Zm160 0h80v-320h-80v320ZM160-160q-33 0-56.5-23.5T80-240v-480q0-33 23.5-56.5T160-800h640q33 0 56.5 23.5T880-720v480q0 33-23.5 56.5T800-160H160Zm0-80h640v-480H160v480Zm0 0v-480 480Z" />
  </IconBase>
);

export default PausedModule;
